import {
  Flex,
  SimpleGrid,
  Icon,
  Text,
  useColorModeValue,
  Card,
  Box,
  Link,
} from "@chakra-ui/react";
import React from "react";
import hollaexLogo from "/public/testimonials/hollaex_logo.jpg"
import { BluredColorsBackground } from "@astrolab/web/ui/ColoredElements";
import { FaTelegram } from "react-icons/fa";
import { StyledTitle } from "./Styling/StyledTitle";
import { IconType } from "react-icons";
import { StaticImageData } from "next/image";
import CustomImage from "@astrolab/web/ui/Image/CustomImage";
import { useTranslations } from "next-intl";


const Testimonial: React.FC<{
  title: string, 
  desc: string, 
  descP2?: string, 
  image?: string, 
  icon?: IconType, 
  name: string, 
  job: string, 
  company: string, 
  avatar: StaticImageData | string, 
  link: string,
  [rest: string]: any;
}> = ({ title, desc, descP2, image, icon, name, job, company, avatar, link, ...rest }) => {

  const textColor = useColorModeValue("#120F43", "white");
  const textColorSecondary = useColorModeValue("gray.600", "gray.300");
  const brandColor = useColorModeValue("brand.500", "white");
  return (
    <Card
      p={{ base: "20px", md: "28px" }}
      borderRadius="16px"
      w="100%"
      direction={{ base: "column" }}
      overflow="hidden"
      bgSize="cover"
      position="relative"
      {...rest}
    >
      <Text color={textColor} fontSize="22px" fontWeight="700" mb="14px">
        {title}
      </Text>
      <Box
        fontSize={{ base: "md", md: "md", xl: "md" }}
        fontWeight="500"
        letterSpacing="0px"
        lineHeight={{ base: "24px", md: "28px" }}
        mb="30px"
      >
        <Text>
          {desc}
        </Text>
          {descP2 ? 
          <Text mt="4px">
            {descP2}
          </Text> : ""}
      </Box>
      <Flex alignItems="center">
        {avatar ? <CustomImage style={{height: "48px", width: "48px", marginRight: "16px"}}src={avatar} alt="avatar" />: <Icon
          color={textColor}
          as={icon}
          h='40px'
          w='40px'
          me="16px" 
        />}
        <Box>
          <Text
            color={textColor}
            letterSpacing="0px"
            fontSize="md"
            fontWeight="700"
          >
            {name}
          </Text>
          <Flex align="center">
            <Text
              color={textColorSecondary}
              fontSize="sm"
              fontWeight="500"
              letterSpacing="0px"
              me="5px"
            >
              {job}
            </Text>
            {
              link ? <Link
                isExternal={true}
                rel="nofollow"
                href={link}
                color={brandColor}
                fontWeight="700"
                letterSpacing="0px"
                fontSize="sm"
              >
                {company}
              </Link>: ""
            }
            
          </Flex>
        </Box>
      </Flex>
    </Card>
  );
}


const Testimonials: React.FC<{ 
  [rest: string]: any
}> = ({
  ...rest
}) => {
  const t = useTranslations('home.community')
  return (
    <Flex
      bgSize="cover"
      w="100%"
      direction={{ base: "column" }}
      overflow="hidden"
      position="relative"
      {...rest}
    >
      <StyledTitle>
        {t('a-word-from')}
      </StyledTitle>
      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        gap="20px"
        position={"relative"}
      >
        <BluredColorsBackground/>
        <SimpleGrid columns={1} gap="20px">
          <Testimonial
            avatar=""
            icon={FaTelegram}
            link=""
            title="Insane"
            name={t('testimonials.octobot-user')}
            desc='"I&#39;ve been using [OctoBot with] these setting for a while now, tweaking them, the past year I&#39;m at an overall profit of about 1000% (3K -> 30K), insane, 2021 was a great year!”'
            job={t('testimonials.community-member', {since: '2022'})}
            company=""
          />
          <Testimonial
            avatar=""
            icon={FaTelegram}
            link=""
            title="An incredible journey"
            name="Tim Hermans"
            desc='"I have been using Octobot for a couple of years now and it has been an incredible journey! Octobot is an open-source gem that has truly revolutionized my trading. The fact that it is built on open-source principles is really standing out as it means that the possibilities are endless.'
            descP2='I have had the pleasure of customizing Octobot to suit my specific needs, The OctoBot community is vibrant and supportive, connecting like-minded individuals with a passion for the project.”'
            job={t('testimonials.community-member', {since: '2021'})}
            company=""
          />
        </SimpleGrid>
        <SimpleGrid columns={1} gap="20px">
          <Testimonial
            avatar={hollaexLogo}
            link="https://www.hollaex.com/"
            title="Exceptional open-source crypto trading bot"
            name="Adrian Pollard"
            desc='"Octobot is an exceptional open-source crypto trading bot that excels in its performance. It seamlessly integrates with exchanges through our own open-source white-label exchange system HollaEx®, offering strategies for market markets, OTC dealers, liquidity providers, price discovery and of course profit!'
            descP2='Octobot is a valuable asset to the crypto ecosystem, and we are delighted to be partners.”'
            job="Co-funder of "
            company="HollaEx"
          />
          <Testimonial
            avatar=""
            icon={FaTelegram}
            link=""
            title="Very reactive"
            name={t('testimonials.octobot-user')}
            desc='"I wanted something and devs bring it within few weeks (staggered). I like devs, which are active and working for cumunity ideas.”'
            job={t('testimonials.senior-community-member')}
            company=""
          />
        </SimpleGrid>
      </SimpleGrid>
    </Flex>
  );
}

export {Testimonials}