import { JSONLDScript } from "@astrolab/web/common/JsonLD"
import { getWebsite } from "@astrolab/web/lib/json-ld"
import { removeTrailingSlash } from "@astrolab/web/lib/navigation"

const WebsiteJsonLD: React.FC = () => {
  const url = removeTrailingSlash(process.env.NEXT_PUBLIC_SITE_URL || '')
  return (
    <JSONLDScript 
      content={getWebsite("OctoBot", url)}
    />
  )
}

export { WebsiteJsonLD }
