import {
  Box,
  Card,
  Flex,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { BotStats } from '@lib/fetching'
import { useTranslations } from 'next-intl'

const StatsCard: React.FC<{
  title: string
  stat: number | string
  children?: any
}> = ({ title, stat, children }) => {
  const cardBg = useColorModeValue(
    'linear-gradient(85.3deg, #3D1DFF 13.04%, #6147FF 38.28%, #D451FF 59.31%, #EC458D 80.35%)',
    'inherit'
  )
  const textColor = useColorModeValue('transparent', 'white')
  const bgClip = useColorModeValue('text', 'inherit')
  return (
    <Card
      justifyContent="center"
      alignItems="center"
      pt="44px"
      pb="56px"
      w="100%"
      mb="30px"
      borderRadius="16px"
      direction={{ base: 'column' }}
    >
      <Flex>
        {children}
        <Text
          textAlign={'center'}
          color={textColor}
          bgImage={cardBg}
          fontSize={{ base: '60px', lg: '80px' }}
          lineHeight={'100%'}
          fontWeight="800"
          bgClip={bgClip}
          mb="14px"
        >
          {stat}
        </Text>
      </Flex>
      <Text fontSize={{ base: '3xl' }} fontWeight="700" letterSpacing="0px">
        {title}
      </Text>
    </Card>
  )
}

const CommunityStats: React.FC<{
  stats: BotStats
}> = ({ stats }) => {
  const t = useTranslations('home.community')
  return (
    <Box
      py={5}
    >
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 5, lg: 8 }}>
        <StatsCard
          title={'OctoBots'}
          stat={stats.bots == 0 ? '-' : stats.bots}
        />
        <StatsCard title={t('trades')} stat="10 million +" />
      </SimpleGrid>
    </Box>
  )
}

export { CommunityStats }
