'use client'

import React from 'react'
import { Box, Button, SimpleGrid, useColorModeValue } from '@chakra-ui/react'
import { BotPricing } from '@astrolab/web/components/bot/BotPricing'
import { ProductIntervalSwitcher } from '@astrolab/web/components/product/PlanSelection'
import {
  ProductPlan,
  ProductPlanInterval,
  ProductWithPlan,
  bestHostingProductSlug,
  bestSubscriptionProductSlug,
  defaultProductPlanInterval,
} from '@astrolab/back/types'
import { GetPlanLink } from '@astrolab/web/common/Pricing/HostingPricingData'
import { useLocale, useTranslations } from 'next-intl'
import { LocalizedLink } from '@astrolab/web/common/LocalizedLink'
import {
  getProductPlanPrice,
  isInPrivateEarlyAccess,
} from '@astrolab/back/product/product.lib'

const Plans: React.FC<{
  products: ProductWithPlan[]
  defaultProductInterval?: ProductPlanInterval
  additionnalProductPlanIntervals?: Array<ProductPlanInterval>
  highlightedProductSlugs?: Array<string>
  translationBase?: string
  highlightedText?: string
  [rest: string]: any
}> = ({
  products,
  defaultProductInterval = defaultProductPlanInterval,
  additionnalProductPlanIntervals,
  highlightedProductSlugs = [bestSubscriptionProductSlug, bestHostingProductSlug],
  translationBase = 'home',
  highlightedText,
  ...rest
}) => {
    const t = useTranslations(`${translationBase}.pricing`)
    const locale = useLocale()
    const [productPlanInterval, setProductPlanInterval] =
      React.useState<ProductPlanInterval>(defaultProductInterval)
    const textColor = useColorModeValue('secondaryGray.900', 'white')

    const filteredPlanProducts = products.filter((planProduct) => {
      const productPlan = planProduct?.plans?.find(
        (productPlan: ProductPlan) => productPlan.interval == productPlanInterval,
      )
      const price = productPlan ? getProductPlanPrice(productPlan) : undefined

      return productPlanInterval == 'one_time' ? price != undefined : true
    })

    return (
      <>
        {additionnalProductPlanIntervals ? (
          <Box mx="auto" w="fit-content" mb="15px">
            <ProductIntervalSwitcher
              defaultProductInterval={defaultProductInterval}
              additionnalProductPlanIntervals={additionnalProductPlanIntervals}
              textColor={textColor}
              productPlanInterval={productPlanInterval}
              setProductPlanInterval={setProductPlanInterval}
            />
          </Box>
        ) : null}
        <SimpleGrid
          minChildWidth="300px"
          spacing={{ base: 5, lg: 15 }}
          w="100%"
          px={{ base: '10px', sm: 0 }}
        >
          {filteredPlanProducts.map((product: ProductWithPlan, index: number) => {
            const isHighlighted = highlightedProductSlugs.includes(product.slug)
            const isPrivateEarlyAccess = isInPrivateEarlyAccess(product)

            return (
              <BotPricing
                key={index}
                product={product}
                billingInterval={productPlanInterval}
                mx="auto"
                highlighted={isHighlighted}
                highlightedText={highlightedText || t('most-popular')}
                highlightedTextColor={isHighlighted ? 'white' : undefined}
                highlightedBorderColor={isHighlighted ? 'brand.500' : undefined}
                pb="3"
                actionButton={
                  <Box mx="auto" textAlign={'center'} mt="30px">
                    <LocalizedLink
                      href={GetPlanLink(product, locale)}
                      noFollow={true}
                    >
                      <Button
                        variant={isPrivateEarlyAccess ? 'outline' : 'brand'}
                        fontSize="md"
                        fontWeight="500"
                        color={isPrivateEarlyAccess ? undefined : 'white'}
                        px={{ base: '20px', lg: '50px' }}
                        data-ph-capture-attribute-button-role="planPreview"
                        data-ph-capture-attribute-product-slug={product?.slug}
                      >
                        {isPrivateEarlyAccess
                          ? t('early-access')
                          : t('call-to-action')}
                      </Button>
                    </LocalizedLink>
                    {product.trial_period && productPlanInterval != 'one_time' ? (
                      <Box mt="1">
                        {t('free-trial-message', {
                          duration: product.trial_period,
                        })}
                      </Box>
                    ) : null}
                  </Box>
                }
                {...rest}
              />
            )
          })}
        </SimpleGrid>
      </>
    )
  }

export { Plans }
