'use client'

import {
  Box,
  Text,
  Switch,
  FormControl,
  useBreakpointValue,
  Flex,
} from '@chakra-ui/react'

import React, { useState } from 'react'
import Card from '@astrolab/web/ui/Card'
import { useTranslations } from 'next-intl'
import ProfitBadge from '@astrolab/web/ui/Statistics/ProfitBadge'

const PaperTradingPreview: React.FC<{
  [rest: string]: any
}> = ({ bestStrategyByRisk, resultByStrategy, ...rest }) => {
  const t = useTranslations('home.start-with-paper-trading')
  const translationPrefix = useBreakpointValue({ base: '-sm', lg: '' })
  const [isPaperTrading, setIsPaperTrading] = useState(true)

  const getInterpretationKey = (profit: number) => {
    return profit > 0
      ? isPaperTrading
        ? 'paper-trading-gains'
        : 'real-gains'
      : isPaperTrading
        ? 'paper-trading-loss'
        : 'real-loss'
  }
  const getInterpretation = (profit: number) => {
    return t(getInterpretationKey(profit), { value: Math.abs(profit) })
  }

  const changeIsPaperTrading = () => {
    if (isPaperTrading) {
      setIsPaperTrading(false)
    } else {
      setIsPaperTrading(true)
    }
  }

  return (
    <Card
      w={{ base: '100%', lg: '75%', xl: '75%' }}
      px={{ base: 1, lg: 4 }}
      mx="auto"
      {...rest}
    >
      <Box textAlign={'center'} mx="auto" py={{ base: 2, lg: 5 }}>
        <Text textAlign={'center'} fontSize={'2xl'} fontWeight={600}>
          {t('example')}
        </Text>
        <FormControl
          display="flex"
          alignItems="center"
          fontSize={'2xl'}
          justifyContent={'center'}
          pt={{ base: 2, lg: 8 }}
          pb="2"
        >
          <Text
            fontWeight={isPaperTrading ? 500 : 600}
            onClick={changeIsPaperTrading}
            cursor={'pointer'}
          >
            {t(`real-trading${translationPrefix}`)}
          </Text>
          <Switch
            id="use-paper-trading-switch"
            aria-label="virtual-real money switch"
            onChange={changeIsPaperTrading}
            isChecked={isPaperTrading}
            px="2"
          />
          <Text
            fontWeight={isPaperTrading ? 600 : 500}
            onClick={changeIsPaperTrading}
            cursor={'pointer'}
          >
            {t(`paper-trading${translationPrefix}`)}
          </Text>
        </FormControl>
      </Box>
      <Flex
        fontWeight={600}
        my="2"
        mx="auto"
        textAlign="center"
        fontSize={{ base: 'lg', md: 'xl' }}
        direction={{ base: 'column', md:'row' }}
        gap={2}
      >
        <Box as="span" display={{ base: 'block', md: 'inline' }}>
          {t('if-the-strategy-made')}
        </Box>
        <Box as="span" display={{ base: 'block', md: 'inline' }}>
          <ProfitBadge profit={15} fontSize={{ base: 'md', md: 'xl' }} />
        </Box>
        <Box as="span" display={{ base: 'block', md: 'inline' }}>
          {getInterpretation(300)}
        </Box>
        <Box as="span" display={{ base: 'block', md: 'inline' }}>
          <ProfitBadge
            alignSelf={'start'}
            profit={300}
            isCurrency={true}
            fontSize={{ base: 'md', md: 'xl' }}
          />
        </Box>
      </Flex>
      <Flex
        fontWeight={600}
        my="2"
        mx="auto"
        textAlign="center"
        fontSize={{ base: 'lg', md: 'xl' }}
        direction={{ base: 'column', md:'row' }}
        gap={2}
      >
        <Box as="span" display={{ base: 'block', md: 'inline' }}>
          {t('if-the-strategy-made')}
        </Box>
        <Box as="span" display={{ base: 'block', md: 'inline' }}>
          <ProfitBadge profit={-5} fontSize={{ base: 'md', md: 'xl' }} />
        </Box>
        <Box as="span" display={{ base: 'block', md: 'inline' }}>
          {getInterpretation(-100)}
        </Box>
        <Box as="span" display={{ base: 'block', md: 'inline' }}>
          <ProfitBadge
            alignSelf={'start'}
            profit={-100}
            isCurrency={true}
            fontSize={{ base: 'md', md: 'lg' }}
          />
        </Box>
      </Flex>
    </Card>
  )
}

export { PaperTradingPreview }
