'use client'

import { Link } from '@chakra-ui/next-js'
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  useColorModeValue,
  Text,
} from '@chakra-ui/react'
import {
  ProductWithPlanAndResults,
} from '@astrolab/back/types'
import { StrategyOverview } from '@astrolab/web/components/product/ProductOverview/StrategyOverview'
import { getOctoBotCloudAppUrl } from '@astrolab/back/lib/urls'
import RiskIcon from '@astrolab/web/ui/Risk/RiskIcon'
import { getRiskText } from '@astrolab/web/ui/Risk/risk'
import { motion } from 'framer-motion'
import { useLocale, useTranslations } from 'next-intl'
import { getLocalizedUrl } from '@astrolab/web/lib/locales'
import { defaultLocale } from '@astrolab/web/constants'
import { Exchange } from '@astrolab/back/exchange/exchange.entity'

const StrategiesGrid: React.FC<{
  strategies?: Array<ProductWithPlanAndResults> | undefined
  exchanges?: Array<Exchange>
  contentW: any
  [rest: string]: any
}> = ({ strategies, exchanges, contentW, ...rest }) => {
  const MotionBox = motion(Box)
  const locale = useLocale()
  const t = useTranslations('home.strategies')
  const tRisk = useTranslations('global.risk_levels')
  const textColor = useColorModeValue('secondaryGray.900', 'white')

  const bgItem = useColorModeValue(
    {
      bg: 'white',
      boxShadow: '0px 40px 58px -20px rgba(112, 144, 176, 0.12)',
      cursor: 'pointer',
    },
    { bg: 'navy.700', boxShadow: 'unset', cursor: 'pointer' },
  )

  return (
    <Box w={contentW} mx="auto" {...rest}>
      <SimpleGrid
        minChildWidth={{ base: '300px', md: '350px' }}
        spacing={{ base: '35px', md: '15px' }}
        textAlign="start"
        alignItems="start"
        w="100%"
        mx="auto"
      >
        {strategies?.map(
          (product: ProductWithPlanAndResults | undefined, index: number) => {
            const productLink = `${getLocalizedUrl(getOctoBotCloudAppUrl() || '', locale, defaultLocale)}/strategies/${
              product?.slug
            }`
            return (
              <MotionBox
                key={index}
                whileHover={{
                  scale: 1.05,
                }}
                transition={{ duration: 0.2 }}
              >
                <StrategyOverview
                  product={product!}
                  imageLink={productLink}
                  actionButton={
                    <Link
                      href={productLink}
                      w="100%"
                      px={{ base: '10px', md: '30px', lg: '50px' }}
                    >
                      <Button
                        variant="brand"
                        fontSize="sm"
                        fontWeight="500"
                        borderRadius="70px"
                        w="100%"
                        py="5px"
                        data-ph-capture-attribute-button-role="strategyPreview"
                        data-ph-capture-attribute-product-slug={product?.slug}
                      >
                        {t('call-to-action')}
                      </Button>
                    </Link>
                  }
                  display={{
                    price: false,
                    risk: false,
                  }}
                  highlightedText={
                    product?.attributes?.risk ? (
                      <Flex direction="column" textAlign="center">
                        <Text color={textColor} ms="5px" my="auto" mb="5px">
                          {getRiskText(tRisk, product.attributes.risk)}
                        </Text>
                        <RiskIcon
                          level={product.attributes.risk || undefined}
                          size={20}
                          tooltipText={getRiskText(
                            tRisk,
                            product.attributes.risk,
                          )}
                        />
                      </Flex>
                    ) : null
                  }
                  exchanges={exchanges}
                  _hover={bgItem}
                  boxShadow="unset"
                  transition="0.3s linear"
                  maxW={'400px'}
                  mx="auto"
                />
              </MotionBox>
            )
          },
        )}
      </SimpleGrid>
    </Box>
  )
}

export { StrategiesGrid }
