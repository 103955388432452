'use client'

import React from 'react'
import { Box, Button } from '@chakra-ui/react'
import { ProductWithPlan } from '@astrolab/back/types'
import { useLocale, useTranslations } from 'next-intl'
import { LocalizedLink } from '@astrolab/web/common/LocalizedLink'
import { Plans } from './Plans'

const PlansPreview: React.FC<{
  plans: ProductWithPlan[]
  highlightedProductSlugs?: Array<string>
  highlightedText?: string
  [rest: string]: any
}> = ({ plans, highlightedProductSlugs, highlightedText, ...rest }) => {
  const t = useTranslations('home.pricing')
  const locale = useLocale()
  return (
    <Box w="100%" mx="auto" py={{ base: 0, sm: 5 }} {...rest}>
      <Plans
        products={plans}
        defaultProductInterval="monthly"
        additionnalProductPlanIntervals={['yearly', 'one_time']}
        highlightedProductSlugs={highlightedProductSlugs}
        highlightedText={highlightedText}
      />
      <Box mt="6" textAlign="center">
        <LocalizedLink locale={locale} href="/pricing">
          <Button
            variant="outline"
            fontWeight="500"
            fontSize={{ base: '18px', md: '20px' }}
            py="20px"
            h="50px"
            w={{ base: '200px', md: '300px' }}
            borderColor="brand"
          >
            {t('plans-details')}
          </Button>
        </LocalizedLink>
      </Box>
    </Box>
  )
}

export { PlansPreview }
