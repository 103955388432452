import React, { ForwardedRef, forwardRef, useRef } from "react";
import { Flex, FlexProps, useColorModeValue } from "@chakra-ui/react";
import { AnimatedBeam } from "./AnimatedBeam";
import { SiBinance, SiKucoin, SiTradingview } from "react-icons/si";
import OctoBotLogo from "@astrolab/web/ui/OctoBotLogo";

interface CircleProps extends FlexProps {
	children?: React.ReactNode;
}

const Circle = forwardRef<HTMLDivElement, CircleProps>(
	({ children, ...rest }, ref: ForwardedRef<HTMLDivElement>) => {
		const textColor = useColorModeValue('white', 'secondaryGray.900')
		return (
			<Flex
				ref={ref}
				align="center"
				justify="center"
				boxSize="80px"
				borderRadius="full"
				borderWidth="2px"
				borderColor="gray.200"
				bg={textColor}
				p={3}
				shadow="md"
				{...rest}
			>
				{children}
			</Flex>
		);
	}
);

Circle.displayName = "Circle";

const TradingViewBeam: React.FC<{
	contentW: any,
	[rest: string]: any
}> = ({ contentW, ...rest }) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const div1Ref = useRef(null);
	const div2Ref = useRef(null);
	const div3Ref = useRef(null);
	const div4Ref = useRef(null);

	return (
		<Flex
			ref={containerRef}
			h={{ base: "200px", md: "300px" }}
			align="center"
			justify="center"
			w={contentW}
			maxW="800px"
			mx="auto"
			px={{ base: 2, md: 10 }}
			{...rest}
		>
			<Flex direction="row" justify="space-between" w="full" zIndex={1}>
				<Flex direction="column" justify="center" gap={2}>
					<Circle ref={div1Ref} boxSize="92px">
						<SiTradingview style={{
							width: "48px",
							height: "48px"
						}} />
					</Circle>
				</Flex>
				<Flex direction="column" justify="center">
					<Circle ref={div2Ref} boxSize="92px">
						<OctoBotLogo />
					</Circle>
				</Flex>
				<Flex direction="column" justify="center" gap={4}>
					<Circle ref={div3Ref}>
						<SiBinance style={{
							width: "36px",
							height: "36px"
						}} />
					</Circle>
					<Circle ref={div4Ref}>
						<SiKucoin style={{
							width: "36px",
							height: "36px"
						}} />
					</Circle>
				</Flex>
			</Flex>

			<AnimatedBeam containerRef={containerRef} fromRef={div1Ref} toRef={div2Ref} />
			<AnimatedBeam containerRef={containerRef} fromRef={div2Ref} toRef={div3Ref} />
			<AnimatedBeam containerRef={containerRef} fromRef={div2Ref} toRef={div4Ref} />
		</Flex>
	);

}

export { TradingViewBeam }
