import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { IconBox } from '@astrolab/web/ui/IconBox/IconBox'
import { DashCurveDown, DashCurveUp } from '@astrolab/web/ui/Icon'
import React from 'react'
import { FaBolt, FaChartLine } from 'react-icons/fa'
import { ImSphere } from 'react-icons/im'
import { StyledTitle } from './Styling/StyledTitle'
import { useLocale, useTranslations } from 'next-intl'
import { LocalizedLink } from '@astrolab/web/common/LocalizedLink'
import { StyledSubtitle } from './Styling/StyledSubtitle'

const SetupSteps: React.FC<{
  [rest: string]: any
}> = ({ ...rest }) => {
  const t = useTranslations('home.steps')
  const locale = useLocale()
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const brandColor = useColorModeValue('brand.500', 'white')
  const dashColor = useColorModeValue('brand.500', 'whiteAlpha.500')
  const secondaryTextColor = useColorModeValue('gray.600', 'white')
  const shadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'unset',
  )
  const completeShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'inset 0px 4px 4px rgba(255, 255, 255, 0.2)',
  )
  const boxBg = useColorModeValue(
    'white',
    'linear-gradient(180deg, #1F2A4F 0%, #18224D 50.63%, #111C44 100%)',
  )
  return (
    <Box w="100%" {...rest}>
      <StyledTitle>{t('how-to-start-your-octobot')}</StyledTitle>
      <StyledSubtitle>{t('our-goal')}</StyledSubtitle>
      <Flex direction={{ base: 'column', md: 'row' }} justifyContent="center">
        <Flex direction="column" align="center" justify="center">
          <IconBox
            mb="16px"
            w="100px"
            h="100px"
            bg={boxBg}
            shadow={shadow}
            boxShadow={completeShadow}
          >
            <Icon w="38px" h="38px" as={FaBolt} color={brandColor} />
          </IconBox>
          <Text
            textAlign="center"
            color={textColor}
            fontSize="xl"
            fontWeight="700"
            mb="10px"
          >
            1. {t('pick-your-strategy')}
          </Text>
        </Flex>
        <Flex mx="60px" direction="column" align="center" justify="center">
          <IconBox
            mb="16px"
            w="100px"
            h="100px"
            bg={boxBg}
            shadow={shadow}
            boxShadow={completeShadow}
          >
            <Icon w="38px" h="38px" as={ImSphere} color={brandColor} />
          </IconBox>
          <Text
            textAlign="center"
            color={textColor}
            fontSize="xl"
            fontWeight="700"
            mb="10px"
          >
            2. {t('select-your-exchange')}
          </Text>
        </Flex>
        <Flex direction="column" align="center" justify="center">
          <IconBox
            mb="16px"
            w="100px"
            h="100px"
            bg={boxBg}
            shadow={shadow}
            boxShadow={completeShadow}
          >
            <Icon w="38px" h="38px" as={FaChartLine} color={brandColor} />
          </IconBox>
          <Text
            textAlign="center"
            color={textColor}
            fontSize="xl"
            fontWeight="700"
            mb="10px"
          >
            3. {t('follow-your-gains')}
          </Text>
        </Flex>
      </Flex>

      <Box mt="6" textAlign="center">
        <LocalizedLink
          href="/investing/introduction"
          forcedLocale={true}
          locale={locale}
        >
          <Button
            variant="outline"
            fontWeight="500"
            fontSize={{ base: '18px', md: '20px' }}
            mt="10px"
            h="50px"
            w={{ base: '200px', md: '300px' }}
            borderColor="brand"
          >
            {t('call-to-action')}
          </Button>
        </LocalizedLink>
      </Box>
    </Box>
  )
}

export { SetupSteps }
