'use client'

import {
  Box,
  useColorModeValue,
  Button,
  useBreakpointValue,
} from '@chakra-ui/react'
import { ProductWithPlanAndResults } from '@astrolab/back/types'
import { motion } from 'framer-motion'
import IndexOverview from '@astrolab/web/components/product/ProductOverview/IndexOverview'
import { useLocale, useTranslations } from 'next-intl'
import Carousel from '@astrolab/web/ui/Carousel'
import { defaultLocale } from '@astrolab/web/constants'
import { getStartedLinkUrl } from '@lib/url'
import { LocalizedLink } from '@astrolab/web/common/LocalizedLink'
import { useRouter } from 'next/navigation'
import { Exchange } from '@astrolab/back/exchange/exchange.entity'
import { Cryptocurrency } from '@astrolab/back/public-data/public-data.entity'

const IndexesCarousel: React.FC<{
  indexes?: Array<ProductWithPlanAndResults> | undefined
  exchanges?: Array<Exchange>
  cryptocurrencies: Array<Cryptocurrency>
  contentW: any
  isAnimated?: boolean
  defaultCountToShow?: number
  [rest: string]: any
}> = ({
  indexes,
  exchanges,
  cryptocurrencies,
  contentW,
  isAnimated = false,
  defaultCountToShow = 3,
  ...rest
}) => {
  const MotionBox = motion(Box)
  const locale = useLocale()
  const router = useRouter()
  const t = useTranslations('home.indexes')

  const bgItem = useColorModeValue(
    {
      bg: 'white',
      boxShadow: '0px 40px 58px -20px rgba(112, 144, 176, 0.12)',
      cursor: 'pointer',
    },
    { bg: 'navy.700', boxShadow: 'unset', cursor: 'pointer' },
  )

  return (
    <Box w={contentW} mx="auto" {...rest}>
      <Carousel
        childrenCount={indexes?.length || 0}
        defaultCountToShow={useBreakpointValue({
          base: 1,
          md: defaultCountToShow,
        })}
        playSpeed={isAnimated ? 2000 : undefined}
        responsiveCount={{
          xxxl: defaultCountToShow,
          xxl: defaultCountToShow,
          xl: defaultCountToShow,
          lg: defaultCountToShow,
        }}
      >
        {indexes?.map((product: ProductWithPlanAndResults, index: number) => {
          return (
            <MotionBox
              key={index}
              whileHover={{
                scale: 1.1,
              }}
              transition={{ duration: 0.2 }}
              py="28px"
            >
              <IndexOverview
                product={product!}
                cryptocurrencies={cryptocurrencies}
                exchanges={exchanges}
                actionButton={
                  <Box mx="auto">
                    <LocalizedLink
                      href={getStartedLinkUrl(locale, defaultLocale, false)}
                    >
                      <Button variant="brand">{t('call-to-invest')}</Button>
                    </LocalizedLink>
                  </Box>
                }
                onClick={() =>
                  router.push(getStartedLinkUrl(locale, defaultLocale, false))
                }
                display={{
                  minimumFunds: false,
                }}
                maxW="300px"
                mx="auto"
                _hover={bgItem}
                boxShadow="unset"
                transition="0.3s linear"
              />
            </MotionBox>
          )
        })}
      </Carousel>
    </Box>
  )
}

export { IndexesCarousel }
