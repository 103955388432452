import { Text } from '@chakra-ui/react'

const StyledSubtitle: React.FC<{
  children: any
  [rest: string]: any
}> = ({ children, ...rest }) => {
  return (
    <Text fontSize="lg" fontWeight="400" mb="70px" textAlign="center" {...rest}>
      {children}
    </Text>
  )
}

export { StyledSubtitle }
