'use client'

import {
  Box,
  Flex,
  GridItem,
  Select,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import Card from '@astrolab/web/ui/Card'
import { useLocale, useTranslations } from 'next-intl'
import {
  ProductResultAsset,
  ProductResultAssetHistory,
  ProductRiskAttribute,
  ProductWithPlanAndResults,
} from '@astrolab/back/types'
import { getProductTranslation } from '@astrolab/commons/product/product.lib'
import { fixValueToDisplay } from '@astrolab/back/lib/formatting'
import ProfitBadge from '@astrolab/web/ui/Statistics/ProfitBadge'
import LineChart from '@astrolab/web/ui/Charts/LineChart'

const StrategyProfitsChart: React.FC<{
  bestStrategyByRisk: Record<
    ProductRiskAttribute,
    ProductWithPlanAndResults | undefined
  >
  resultByStrategy: Record<string, ProductResultAsset>
  [rest: string]: any
}> = ({ bestStrategyByRisk, resultByStrategy, ...rest }) => {
  const initialStrategy =
    bestStrategyByRisk['High'] ||
    bestStrategyByRisk['Moderate'] ||
    bestStrategyByRisk['Low']

  const t = useTranslations('home.profit-chart')
  const locale = useLocale()
  const optionsStyle = useColorModeValue({ color: 'black' }, { color: 'white' })
  const chartHeight = useBreakpointValue({ base: '200px' })
  const defaultPortfolioValue = 1000
  const [initialPortfolio, setInitialPortfolio] = useState(
    defaultPortfolioValue,
  )
  const [selectedStrategy, setSelectedStrategy] = useState(initialStrategy)
  const [profitOffset, setProfitOffset] = useState(0)
  const [selectedStrategyResult, setSelectedStrategyResult] = useState(
    initialStrategy ? resultByStrategy[initialStrategy.id] : undefined,
  )
  const defaultDuration = selectedStrategyResult
    ? Math.ceil(selectedStrategyResult.history.length / 30.5)
    : 1
  const [months, setMonths] = useState(defaultDuration)
  const maxDuration = Math.max(
    ...Object.values(resultByStrategy).map(result => {
      if (result) {
        return Math.ceil(result.history.length / 30.5)
      }
      return 0
    }),
  )
  const filterHistory = (
    result: ProductResultAsset | undefined,
    duration: number,
  ) => {
    if (duration == maxDuration) {
      return result?.history
    }
    const toUseHistory = result?.history.slice(
      Math.max(result?.history.length - duration * 30.5, 0),
    )
    return toUseHistory
  }
  const [filteredHistory, setFilteredHistory] = useState(
    filterHistory(selectedStrategyResult, maxDuration),
  )

  const updateDuration = (value: number) => {
    const history = filterHistory(selectedStrategyResult, value)
    refreshProfitOffset(history)
    setFilteredHistory(history)
    setMonths(value)
  }

  const changedStrategy = async (strategyId: string) => {
    const strategy = Object.values(bestStrategyByRisk).filter(
      strategy => strategy && strategy.id == strategyId,
    )[0]
    if (strategy) {
      setSelectedStrategy(strategy)
      setSelectedStrategyResult(resultByStrategy[strategy.id])
      const history = filterHistory(resultByStrategy[strategy.id], months)
      refreshProfitOffset(history)
      setFilteredHistory(history)
    }
  }

  const refreshProfitOffset = (
    history: ProductResultAssetHistory[] | undefined,
  ) => {
    setProfitOffset((history ? history[0].pfr || history[0].pf : 0) || 0)
  }

  const getPfValueToDisplay = (
    resultHistory: ProductResultAssetHistory,
    initialPortfolioValue: number,
    offset: number,
  ): number | undefined => {
    const pfValue = resultHistory.pfr || resultHistory.pf
    return (
      initialPortfolioValue +
      (((pfValue != undefined ? pfValue : 0) - offset) / 100) *
        initialPortfolioValue
    )
  }

  const lineChartData = useMemo(
    () =>
      (filteredHistory || []).map((resultHistory: ProductResultAssetHistory) =>
        fixValueToDisplay(
          getPfValueToDisplay(resultHistory, initialPortfolio, profitOffset),
        ),
      ) || [],
    [filteredHistory, initialPortfolio, profitOffset],
  )

  const lineChartLegend = useMemo(
    () =>
      (filteredHistory || []).map((resultHistory: ProductResultAssetHistory) =>
        new Date((resultHistory?.t || 0) * 1000).toLocaleDateString('en-US'),
      ) || [],
    [filteredHistory],
  )

  const finalPortfolio = useMemo(
    () =>
      fixValueToDisplay(
        selectedStrategyResult
          ? getPfValueToDisplay(
              selectedStrategyResult.history[
                selectedStrategyResult.history.length - 1
              ],
              initialPortfolio,
              profitOffset,
            )
          : 0,
      ),
    [selectedStrategyResult, initialPortfolio, profitOffset],
  )
  const gains = finalPortfolio - initialPortfolio
  const chartMin = useMemo(() => Math.min(...lineChartData), [lineChartData])

  return (
    <Box textAlign={'center'}>
      <Card
        align="center"
        direction="column"
        w="100%"
        h={{ base: '100%', xl: '90%' }}
        px="0px"
        pb="0"
        {...rest}
      >
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 4 }}
          gap={4}
          px={2}
          fontSize={'lg'}
        >
          <GridItem>
            <Box>{t('strategy')}</Box>
            <Box>
              <Select
                mb="20px"
                px="2"
                onChange={event => changedStrategy(event.target.value)}
                defaultValue={selectedStrategy?.id}
                aria-label="select a strategy to view history from"
              >
                {Object.values(bestStrategyByRisk).map(
                  (strategy: ProductWithPlanAndResults | undefined) => {
                    if (strategy) {
                      const productTranslation = getProductTranslation(
                        strategy.content,
                        locale,
                      )
                      return (
                        <option
                          value={strategy.id || ''}
                          key={productTranslation.name}
                          style={optionsStyle}
                        >
                          {productTranslation.name}
                        </option>
                      )
                    }
                    return null
                  },
                )}
              </Select>
            </Box>
          </GridItem>
          <GridItem>
            <Box>
              {t('initial-portfolio')}{' '}
              <Box as="span" fontWeight={600}>
                ${initialPortfolio}
              </Box>
            </Box>
            <Box mt="2" w={{ base: '100%', lg: '75%' }} px="2">
              <Slider
                min={50}
                max={defaultPortfolioValue * 10}
                defaultValue={defaultPortfolioValue}
                scale={'log'}
                step={50}
                aria-label="initial portfolio demo slider"
                onChange={setInitialPortfolio}
              >
                <SliderTrack h="6px" borderRadius="78px">
                  <SliderFilledTrack bg="brand.500" />
                </SliderTrack>
                <SliderThumb
                  boxShadow="0px 3px 27px -20px rgba(112, 144, 176, 0.51)"
                  w="18px"
                  h="18px"
                  border="4px solid"
                  borderColor="brand.500"
                  aria-label="initial portfolio demo slider thumb"
                />
              </Slider>
            </Box>
          </GridItem>
          <GridItem>
            <Box>{t('duration', { value: months })}</Box>
            <Box mt="2" w={{ base: '100%', lg: '75%' }} px="2">
              <Slider
                min={1}
                max={maxDuration || 1}
                step={1}
                defaultValue={defaultDuration}
                aria-label="initial portfolio demo slider"
                onChange={updateDuration}
              >
                <SliderTrack h="6px" borderRadius="78px">
                  <SliderFilledTrack bg="brand.500" />
                </SliderTrack>
                <SliderThumb
                  boxShadow="0px 3px 27px -20px rgba(112, 144, 176, 0.51)"
                  w="18px"
                  h="18px"
                  border="4px solid"
                  borderColor="brand.500"
                  aria-label="initial portfolio demo slider thumb"
                />
              </Slider>
            </Box>
          </GridItem>
          <GridItem>
            <Box>
              {t('final-portfolio')}{' '}
              <Box as="span" fontWeight={600}>
                ${finalPortfolio}
              </Box>
            </Box>
            <Box fontWeight={600} fontSize={'xl'}>
              <ProfitBadge fontSize="xl" profit={gains} isCurrency={true} />
            </Box>
          </GridItem>
        </SimpleGrid>
        <Flex
          direction={'column'}
          align="center"
          w="100%"
          h="100%"
          pt="0"
          pb="0"
        >
          <Box w="100%">
            <LineChart
              chartData={lineChartData}
              chartLegend={lineChartLegend}
              showLegend={false}
              enableAnimations={true}
              minH={chartHeight}
              yAxisOptions={{
                min: chartMin * 0.75, // chart bottom at min - 25%
              }}
            />
          </Box>
        </Flex>
      </Card>
      <Box fontSize="sm" as="i">
        {t('disclaimer', {
          strategy: selectedStrategy
            ? getProductTranslation(selectedStrategy.content, locale).name
            : '',
          months: months,
        })}
      </Box>
    </Box>
  )
}

export { StrategyProfitsChart }
