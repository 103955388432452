import { JSONLDScript } from "@astrolab/web/common/JsonLD"
import { getAddress, getOrganization, getReview } from "@astrolab/web/lib/json-ld"
import { removeTrailingSlash } from "@astrolab/web/lib/navigation"
import { discordLink } from '@constants'

const OrganizationJsonLD: React.FC<{ t: any }> = ({
  t,
}) => {
  // should only be set on one page of the website 
  // https://developers.google.com/search/docs/appearance/structured-data/organization?hl=fr#technical
  const url = removeTrailingSlash(process.env.NEXT_PUBLIC_SITE_URL || '')
  const sameAsUrls = [
    "https://twitter.com/DrakkarsOctoBot",
    "https://t.me/OctoBot_Project",
    "https://t.me/octobot_trading",
    discordLink,
    "https://www.instagram.com/_octobot_",
    "https://www.youtube.com/@octobot1134",
    "https://www.youtube.com/@OctoBotFr",
    "https://www.linkedin.com/company/octobot-drakkar-software",
    "https://github.com/Drakkar-Software/",
    "https://play.google.com/store/apps/details?id=com.drakkarsoftware.octobotapp",
    "https://apps.apple.com/us/app/octobot-crypto-investment/id6502774175",
    "https://www.facebook.com/profile.php?id=100083147868278",
  ]
  const reviews = [
    getReview(
      5,
      "Simon Gegan",
      "One of the better trading bots as it is open-source. You can use it efficiently and they also have a cloud option if you want to get more out of the bot and save load of time."
    ),
    getReview(
      5,
      "HollaEx Exchange",
      "This open-source trading bot works nicely with open-source exchange HollaEx. It has been a reliable to work with and offers cloud system to get more power and save more time from the bot. Not only do I highly recommend this true crypto trading bot, but our whole team does. Octobot plays a important role in software trading bot and is a obvious labor of love from their small but efficient team. 5 stars -- hands down!"
    )
  ]
  const address = getAddress(
    "15 rue des Halles",
    "Paris",
    "FR",
    "Ile-de-France",
    "75001"
  )
  return (
    <JSONLDScript 
      content={getOrganization(
        "OctoBot cloud",
        "Drakkar-Software",
        "Drakkar-Software SAS",
        t("description"),
        url,
        `${url}/opengraph-image.png`,
        "contact@octobot.cloud",
        sameAsUrls,
        5, 
        5,
        reviews,
        address,
        1,
        10,
        "2021-07-22"
      )}
    />
  )
}

export { OrganizationJsonLD }
