import { cloudHostingProductCategoriesSlug, selfHostingProductCategoriesSlug, subscriptionProductCategoriesSlug } from "@astrolab/back/types";
import { getOctoBotCloudAppUrl } from "@astrolab/back/lib/urls";
import { getLocalizedUrl } from "../../lib/locales";
import { defaultLocale } from "../../constants";
const SELF_HOSTING_PLAN_SLUG = "hosting-self-1";
const SELF_HOSTING_INSTALLATION_URL = "/guides/octobot";
const GetPlanLink = (product, locale)=>{
    var _product_product_categories, _product_product_categories1, _product_product_categories2;
    if (selfHostingProductCategoriesSlug.includes(((_product_product_categories = product.product_categories) == null ? void 0 : _product_product_categories.slug) || "")) {
        return SELF_HOSTING_INSTALLATION_URL;
    }
    const isAppSubscription = subscriptionProductCategoriesSlug.includes(((_product_product_categories1 = product.product_categories) == null ? void 0 : _product_product_categories1.slug) || "");
    const isHostingSubscription = cloudHostingProductCategoriesSlug.includes(((_product_product_categories2 = product.product_categories) == null ? void 0 : _product_product_categories2.slug) || "");
    const path = isAppSubscription ? "/plan" : isHostingSubscription ? "/cloud" : "";
    const baseUrl = getOctoBotCloudAppUrl();
    return `${getLocalizedUrl(baseUrl || "", locale, defaultLocale)}${path}`;
};
export { GetPlanLink, SELF_HOSTING_PLAN_SLUG, SELF_HOSTING_INSTALLATION_URL };
