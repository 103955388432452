'use client'

import ValuesImage from '/public/values/octobot-with-3-happy-relaxed-users-giving-high-five-feeling-safe-and-comfortable.png'
import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import { Link } from '@chakra-ui/next-js'
import {
  FaGithub,
  FaDiscord,
  FaHandsHelping,
  FaUserGraduate,
} from 'react-icons/fa'
import { ListingDetails, StyledListing } from './Styling/StyledListing'
import { StyledTitle } from './Styling/StyledTitle'
import CustomImage from '@astrolab/web/ui/Image/CustomImage'
import { useTranslations } from 'next-intl'
import { discordLink, telegramLink } from '@constants'

const Values: React.FC = () => {
  const tg = useTranslations('global')
  const t = useTranslations('home.values')

  const secondaryTextColor = useColorModeValue('gray.600', 'white')
  const style = useBreakpointValue({
    base: { width: '330px', objectFit: 'contain' },
    md: { width: '660px', objectFit: 'contain' },
    lg: { width: '490px', objectFit: 'contain' },
    xl: { width: '460px', objectFit: 'contain' },
  })

  const listing: ListingDetails[] = [
    {
      title: t('best-strategies-accessible'),
      icon: FaUserGraduate,
    },
    {
      title: t('for-free'),
      icon: FaHandsHelping,
    },
    {
      title: (
        <Text>
          {t('community-available-on')}{' '}
          <Link
            href={telegramLink}
            target="_blank"
            rel="nofollow"
          >
            Telegram
          </Link>{' '}
          {tg('and')}{' '}
          <Link
            href={discordLink}
            target="_blank"
            rel="nofollow"
          >
            Discord
          </Link>
        </Text>
      ),
      icon: FaDiscord,
    },
    {
      title: (
        <Text>
          {t('octobot-is')}{' '}
          <Link
            href="https://github.com/Drakkar-Software/OctoBot"
            target="_blank"
            rel="nofollow"
          >
            open source
          </Link>
          : {t('everyone-can-see-how-it-works')}
        </Text>
      ),
      icon: FaGithub,
    },
  ]

  return (
    <Flex
      w="100%"
      direction={{ base: 'column' }}
      overflow="hidden"
      bgSize="cover"
      position="relative"
    >
      <Flex direction={{ base: 'column-reverse', lg: 'row' }} width="100%">
        <Flex
          w={{ base: '100%' }}
          position="relative"
          mb="0"
          me={{ base: 'auto', xl: '-20px' }}
          ms={{ base: 'auto', xl: '-20px' }}
          justifyContent={'center'}
        >
          <CustomImage
            src={ValuesImage}
            style={style}
            quality={100}
            alt={t('illustration')}
          />
        </Flex>
        <Flex
          direction="column"
          width="stretch"
          my="auto"
          alignItems={{ base: 'center', lg: 'left' }}
        >
          <Box w="100%">
            <StyledTitle>{t('our-values')}</StyledTitle>
            <Box
              fontSize="lg"
              fontWeight="500"
              w="100%"
              mb="40px"
            >
              <Text textAlign={{ base: 'center' }}>
                {t('community-and-transparency')}
              </Text>
            </Box>
            <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="24px">
              <StyledListing listing={listing} idemMb="20px" />
            </SimpleGrid>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export { Values }
