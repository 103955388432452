'use client'

import { Box, Flex, Text } from '@chakra-ui/react'
import { useColorModeValue } from '@chakra-ui/system'
import { ExchangesLogos } from '../../components/Exchanges'
import { CommunityStats } from '../../components/CommunityStats'
import { Testimonials } from '../../components/Testimonials'
import { News } from '../../components/common/News'
import { PlansPreview } from '../../components/Pricing/PlansPreview'
import { GetStartedButton } from '../../components/GetStartedButton'
import {
  ProductResultAsset,
  ProductRiskAttribute,
  ProductWithPlan,
  ProductWithPlanAndResults,
} from '@astrolab/back/types'
import { StyledTitle } from '../../components/Styling/StyledTitle'
import { Values } from '../../components/Values'
import { SetupSteps } from '../../components/Steps'
import { JoinCommunity } from '../../components/JoinCommunity'
import { StrategiesPreview } from '../../components/StrategiesPreview'
import { StrategyProfitsChart } from '../../components/StrategyProfitsChart'
import MainLightImage from '/public/a-man-relaxing-in-his-couch-while-octobot-is-making-money-by-automating-cryptocurrency-strategies-light.png'
import MainDarkImage from '/public/a-man-relaxing-in-his-couch-while-octobot-is-making-money-by-automating-cryptocurrency-strategies-dark.png'
import { InnerContent } from '@astrolab/web/ui/ContentSizing'
import { BotStats } from '@lib/fetching'
import { useEffect } from 'react'
import { useTrackers } from '@astrolab/web/lib/tracking/tracking'
import CustomImage from '@astrolab/web/ui/Image/CustomImage'
import { useLocale, useTranslations } from 'next-intl'
import { getArticles } from '@lib/blogArticles'
import { Exchange } from '@astrolab/back/exchange/exchange.entity'
import { PaperTradingPreview } from '@app/components/PaperTradingPreview'
import { SubscribeToNewsletterBanner } from '@app/components/SubscribeToNewsletterBanner'
import { TradingViewSelector } from '@app/components/TradingViewSelector'
import { OrganizationJsonLD } from '@app/components/JSON_LD/OrganizationJsonLD'
import { WebsiteJsonLD } from '@app/components/JSON_LD/WebsiteJsonLD'
import { StyledSubtitle } from '@app/components/Styling/StyledSubtitle'
import { IndexesPreview } from '@app/components/IndexesPreview'
import { Cryptocurrency } from '@astrolab/back/public-data/public-data.entity'
import { TradingViewBeam } from '@app/components/TradingView/TradingViewBeam'
import { LocalizedLink } from '@astrolab/web/common/LocalizedLink'

const LandingContentPage: React.FC<{
  subscriptionProducts: Array<ProductWithPlan> | undefined
  botStats: BotStats
  bestIndexes: Array<ProductWithPlanAndResults> | undefined
  bestStrategyByRisk: Record<
    ProductRiskAttribute,
    ProductWithPlanAndResults | undefined
  >
  resultByStrategy: Record<string, ProductResultAsset>
  exchanges: Array<Exchange>
  cryptocurrencies: Array<Cryptocurrency>
}> = ({
  subscriptionProducts,
  botStats,
  bestIndexes,
  bestStrategyByRisk,
  resultByStrategy,
  exchanges,
  cryptocurrencies,
}) => {
  const t = useTranslations('home')
  const tOrganization = useTranslations('organization')
  const locale = useLocale()
  const articles = getArticles(locale, useTranslations('home.news'))
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const secondaryTextColor = useColorModeValue('gray.600', 'secondaryGray.600')
  const inversedCardColor = useColorModeValue('secondaryGray.900', 'navy.800')
  const trackers = useTrackers()

  useEffect(() => {
    trackers.capture('user landed')
  }, [trackers])

  const mainTitle = `${t('tagline.part1')} ${t('tagline.part2')}`
  const mainTitleSize = mainTitle.length
  const baseTitleSize = 40
  const pt = { base: '90px', xl: '110px' }
  const pb = { base: '0', md: '110px', xl: '90px' }
  const regularWidth = { base: 'auto', xl: '1200px' }
  const regularContentHorizontalSizing = {
    w: regularWidth,
    px: { base: 4, xl: 0 },
    mx: 'auto',
  }

  return (
    <Box>
      <OrganizationJsonLD t={tOrganization} />
      <WebsiteJsonLD />
      <InnerContent
        direction={{ base: 'column', md: 'row' }}
        maxW={{ xl: '1560px' }}
        pt={{ base: 0, lg: 4 }}
        pb={{ base: 0, lg: 28 }}
      >
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          color={textColor}
          py={{ base: '30px', md: '50px' }}
          w="100%"
        >
          <StyledTitle
            fontSize={{ base: '34px', md: '40px', lg: '37px', xl: '48px' }}
            w="100%"
            fontWeight="700"
            mb={{ base: '10px', md: '20px' }}
            as="h1"
            px={
              mainTitleSize > baseTitleSize
                ? {
                    base: '0px',
                    sm: '40px',
                    md: '0px',
                    lg: '40px',
                    xl: '30px',
                    '2xl': '100px',
                  } // larger than base (ex: french)
                : {
                    base: '0px',
                    sm: '60px',
                    md: '20px',
                    lg: '60px',
                    xl: '50px',
                    '2xl': '119px',
                  } // base size: english-like
            } // ensure part 1 and 2 are one above the other without adding <br/> tag
          >
            {mainTitle}
          </StyledTitle>
          <Text
            fontSize="xl"
            fontWeight="500"
            mb={{ base: '20px', md: '30px' }}
            lineHeight="28px"
            color={secondaryTextColor}
          >
            {t('tagline.subtext-part1')} <br /> {t('tagline.subtext-part2')}
          </Text>
          <Flex alignItems="center">
            <GetStartedButton />
          </Flex>
        </Flex>
        <Flex w="100%">
          <Box p={{ base: '0', md: '5' }}>
            <CustomImage
              src={useColorModeValue(MainLightImage, MainDarkImage)}
              style={{ width: '584px' }}
              quality={100}
              alt={t('images.main')}
              priority={true}
            />
          </Box>
        </Flex>
      </InnerContent>
      <Flex
        direction="column"
        alignItems="center"
        color={textColor}
        id="indexes"
        pt={pt}
        pb={pb}
      >
        <IndexesPreview
          indexes={bestIndexes}
          contentW={regularWidth}
          cryptocurrencies={cryptocurrencies}
          exchanges={exchanges}
        />
      </Flex>
      <Flex
        direction="column"
        alignItems="center"
        id="strategies"
        w={{ base: 'auto', xl: '1300px' }}
        mx="auto"
      >
        <StrategiesPreview
          bestStrategyByRisk={bestStrategyByRisk}
          exchanges={exchanges}
          title={t('strategies.discover')}
          subtitle={t('strategies.explore-strategies')}
          pt={pt}
          pb={pb}
          contentW={regularWidth}
        />
      </Flex>
      <Box {...regularContentHorizontalSizing}>
        <Box id="profits-chart" pt={pt} pb={pb}>
          <Box w={'100%'}>
            <StyledTitle>{t('profit-chart.title')}</StyledTitle>
            <StyledSubtitle mb={12}>
              {t('profit-chart.description')}
            </StyledSubtitle>
            {bestStrategyByRisk['High'] ||
            bestStrategyByRisk['Moderate'] ||
            bestStrategyByRisk['Low'] ? (
              <StrategyProfitsChart
                bestStrategyByRisk={bestStrategyByRisk}
                resultByStrategy={resultByStrategy}
              />
            ) : null}
          </Box>
          <Box textAlign="center" mt="40px">
            <GetStartedButton />
          </Box>
        </Box>
        <Flex
          direction="column"
          alignItems="center"
          color={textColor}
          id="exchanges"
          pt={pt}
          pb={pb}
        >
          <Box textAlign="center" w="100%">
            <StyledTitle>{t('exchanges.supported-exchanges')}</StyledTitle>
            <StyledSubtitle mb={12}>
              {t('exchanges.octobot-partners')}
            </StyledSubtitle>
            <ExchangesLogos exchanges={exchanges} bg={inversedCardColor} />
          </Box>
        </Flex>
        <Flex pt={pt} pb={pb} id="how-to-start">
          <SetupSteps />
        </Flex>
        <Flex
          direction="column"
          alignItems="center"
          pt={pt}
          pb={pb}
          id="tradingview-automation"
        >
          <Box w="100%">
            <StyledTitle w="100%">
              {t('tradingview.title')}
            </StyledTitle>
            <LocalizedLink
                href="/features/tradingview-bot"
                locale={locale}
              >
              <TradingViewBeam contentW={regularWidth} />
            </LocalizedLink>
          </Box>
          <Flex alignItems="center" mt="40px">
            <GetStartedButton
              title={t('tradingview.automate-my-strategy')}
            />
          </Flex>
        </Flex>
        <Flex
          direction="column"
          alignItems="center"
          color={textColor}
          pt={pt}
          pb={pb}
        >
          <Box w={'100%'}>
            <StyledTitle>
              {t('start-with-paper-trading.test-with-paper-trading')}
            </StyledTitle>
            <StyledSubtitle mb={12}>
              {t(
                'start-with-paper-trading.test-with-paper-trading-before-real',
              )}
            </StyledSubtitle>
            <PaperTradingPreview />
          </Box>
          <Flex alignItems="center" mt="40px">
            <GetStartedButton
              title={t('start-with-paper-trading.invest-with-simulated-funds')}
            />
          </Flex>
        </Flex>
        <Box id="values" pt={pt} pb={pb}>
          <Values />
        </Box>
        <Box id="community" pt={pt} pb={pb}>
          <StyledTitle>
            {t('community.trusted-by', {
              count: botStats.bots - (botStats.bots % 5000),
            })}
          </StyledTitle>
          <CommunityStats stats={botStats} />
          <Testimonials pt={pt} />
        </Box>
      </Box>
      <Flex
        direction="column"
        alignItems="center"
        pt={pt}
        pb={pb}
        color={textColor}
      >
        <Box id="plans" textAlign="center" w="100%">
          <StyledTitle {...regularContentHorizontalSizing}>
            {t('pricing.choose-a-plan')}
          </StyledTitle>
          <StyledSubtitle
            mb={{ base: '8', md: '2', xl: '8' }}
            {...regularContentHorizontalSizing}
          >
            {t('pricing.subscription-plans-for-everyone')}
          </StyledSubtitle>
          <PlansPreview plans={subscriptionProducts || []} w={{ base: 'auto', xl: '1400px' }} />
        </Box>
      </Flex>
      <Box {...regularContentHorizontalSizing}>
        <Flex
          direction="column"
          alignItems="center"
          pt={pt}
          pb={pb}
          color={textColor}
          id="newsletter"
        >
          <StyledTitle as="h2" mb="30px">
            {t('newsletter.title')}
          </StyledTitle>
          <SubscribeToNewsletterBanner />
        </Flex>
        <Flex
          direction="column"
          alignItems="center"
          pt={pt}
          pb={pb}
          color={textColor}
          id="news"
        >
          <StyledTitle as="h2" mb="30px">
            {t('news.latest-news')}
          </StyledTitle>
          <News articles={articles} callToAction={t('news.see-all-articles')} />
        </Flex>
        <Flex id="join-the-community" pt={pt}>
          <JoinCommunity discordCount={botStats.discord} />
        </Flex>
      </Box>
    </Box>
  )
}

export { LandingContentPage }
