import {
  Box,
  Flex,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { IconType } from "react-icons";

export type ListingDetails = {
  title: (ReactNode | string)
  icon: IconType
}

const StyledListing: React.FC<{
  listing: ListingDetails[],
  color?: string,
  idemMb?: string
}> = ({ listing, color, idemMb }) => {
  const themeColor = useColorModeValue("brand.500", "white");
  const brandColor = color ? color : themeColor
  return (
    <>
      {listing.map((listing, index) => {
        return (
          <Flex key={index} mb={idemMb}>
            <Icon
              as={listing.icon}
              color={brandColor}
              w="26px"
              h="26px"
              me="10px"
            />
            <Box
              color={brandColor}
              fontWeight="600"
              fontSize="md"
              letterSpacing="0px"
            >
              {listing.title}
            </Box>
          </Flex>
        )
      })}
    </>
  );
}

export {StyledListing}
