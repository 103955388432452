'use client'

import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { ProductWithPlanAndResults } from '@astrolab/back/types'
import { StyledTitle } from './Styling/StyledTitle'
import { useTranslations } from 'next-intl'
import { IndexesCarousel } from './Indexes/IndexesCarousel'
import { LocalizedLink } from '@astrolab/web/common/LocalizedLink'
import { Cryptocurrency } from '@astrolab/back/public-data/public-data.entity'
import { Exchange } from '@astrolab/back/exchange/exchange.entity'

const IndexesPreview: React.FC<{
  indexes?: Array<ProductWithPlanAndResults> | undefined
  cryptocurrencies: Array<Cryptocurrency>
  exchanges?: Array<Exchange>
  contentW: any
  [rest: string]: any
}> = ({ indexes, cryptocurrencies, exchanges, contentW, ...rest }) => {
  const t = useTranslations('home.indexes')

  return (
    <Flex
      direction="column"
      textAlign="center"
      justifyContent="center"
      align="center"
      w="100%"
      position="relative"
      overflowY="hidden"
      {...rest}
    >
      <Box w="100%" textAlign="center">
        <StyledTitle px="4" as="h2">
          {t('title')}
        </StyledTitle>
        <Text px="4" fontSize="lg" as="h3" pb={9}>
          {t('subtitle')}
        </Text>
        <IndexesCarousel
          indexes={indexes}
          contentW={contentW}
          exchanges={exchanges}
          cryptocurrencies={cryptocurrencies}
        />
        <Box mt="6" textAlign="center">
          <LocalizedLink href="/features/crypto-basket">
            <Button
              variant="outline"
              fontWeight="500"
              fontSize={{ base: '18px', md: '20px' }}
              mt="10px"
              h="50px"
              w="fit-content"
              borderColor="brand"
            >
              {t('call-to-action')}
            </Button>
          </LocalizedLink>
        </Box>
      </Box>
    </Flex>
  )
}

export { IndexesPreview }
